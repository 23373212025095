import { useEffect, useRef } from 'react';

const useUpdateEffect = (
  fn: () => void,
  deps: any[] = [],
  enabled?: boolean = undefined
): void => {
  const fnRef = useRef(fn);
  const loadedRef = useRef(enabled === undefined ? false : enabled);

  useEffect(() => {
    fnRef.current = fn;
  }, [fn]);

  useEffect(() => {
    if (loadedRef.current) fnRef.current();
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadedRef.current = enabled === undefined ? true : enabled;
  }, [enabled]);
};

export default useUpdateEffect;
