import moment from 'moment';
import { getMinDate, combineSlots, getBookingItemFields } from 'utils/booking';
import { WeekDay } from 'components/recurrenceOptions';
import useLocalStorage from 'hooks/useLocalStorage';

const unique = (value, index, list) => list.indexOf(value) === index;

const getInitialSlots = (bookingItems = []) => {
  const [date] = bookingItems
    .map(({ startTime }) => moment(startTime))
    .sort((a, b) => a.diff(b));

  return bookingItems
    .filter(({ startTime }) => moment(startTime).isSame(date, 'day'))
    .map(({ startTime, endTime }) => ({
      startTime: moment(startTime).format('HH:mm'),
      endTime: moment(endTime).format('HH:mm'),
    }));
};

const getInitialValues = ({
  id = '',
  venue: { id: venueId } = {},
  clientService: { id: service = '' } = {},
  bookingHost: { id: host = '', label: hostLabel } = {},
  purchaseOrder = '',
  startTime: date = moment().toDate(),
  bookingItems = [],
  recurring = false,
  recurrenceRules = {},
  recurrenceRules: {
    type: recurrenceType = 'daily',
    dayRange = 'days',
    everyNumberOf = '1',
    count = '2',
    weekDay = WeekDay[(moment(date).day() + 6) % 7],
    weekDays = [weekDay],
    dayOfMonthRange = 'weekNumber' in recurrenceRules
      ? 'nthWeekdayOfMonth'
      : 'nthDayOfMonth',
    nthDayOfMonth = moment(date).date(),
    weekNumber = '1',
    rangeEndType = 'count' in recurrenceRules ? 'count' : 'date',
    endDate = getMinDate(moment(date).toDate(), {
      type: recurrenceType,
      dayRange,
      everyNumberOf,
      weekDay,
      weekDays,
      dayOfMonthRange,
      nthDayOfMonth,
      weekNumber,
    }),
  } = {},
} = {}) => ({
  id: String(id),
  venueId,
  spaceIds: bookingItems.map(({ space: { id } = {} }) => id).filter(unique),
  service: String(service),
  host: host ? { value: Number(host), label: hostLabel } : {},
  purchaseOrder: String(purchaseOrder),
  date: moment(date).startOf('day').toDate(),
  slots: combineSlots(getInitialSlots(bookingItems)),
  dates: [],
  bookingItems: getBookingItemFields(bookingItems),
  recurrence: {
    enabled: recurring,
    type: recurrenceType,
    dayRange,
    everyNumberOf: String(everyNumberOf),
    rangeEndType,
    endDate: moment(endDate).startOf('day').toDate(),
    count: String(count),
    weekDay,
    weekDays,
    dayOfMonthRange,
    nthDayOfMonth: Number(nthDayOfMonth),
    weekNumber: String(weekNumber),
    checked: recurring,
  },
});

export { getInitialValues };

const useStoredBooking = (defaultValue = getInitialValues()) => {
  return useLocalStorage('booking', defaultValue);
};

export default useStoredBooking;
