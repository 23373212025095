import classnames from 'classnames';
import { i18n as I18n } from '../../../utils/i18n';

interface Props {
  statusChecked: boolean;
  clashCount: number;
}

const RecurrenceStatus: React.FC<Props> = ({
  statusChecked = false,
  clashCount = null,
}) => {
  const classes = classnames({
    // eslint-disable-next-line camelcase
    recurrence__status: true,
    'recurrence__status--success': clashCount === 0,
    'recurrence__status--error': clashCount > 0,
  });

  const iconClasses = classnames({
    // eslint-disable-next-line camelcase
    space__icon: true,
    fas: true,
    'fa-exclamation-triangle': clashCount !== 0,
    'fa-check-circle': clashCount === 0,
  });

  return (
    statusChecked && (
      <div
        className={classes}
        data-testid="recurrenceStatus"
        data-cy="recurrenceStatus"
      >
        {I18n.t('quick_book.recurrence.clash_count', { count: clashCount })}
        <i className={iconClasses} />
      </div>
    )
  );
};

export default RecurrenceStatus;
