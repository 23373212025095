import { FC } from 'react';
import classNames from 'classnames';

import InputError from '../inputError';

interface Props {
  label: string;
  name: string;
  value: any;
  id: string;
  disabled?: boolean;
  checked?: boolean;
  onChange?: () => any;
  error?: boolean;
  errorMsg?: string;
  'data-testid'?: string;
  'data-type'?: 'string' | 'number' | 'bool';
}

const RadioButton: FC<Props> = ({
  label,
  name,
  value,
  id,
  disabled = false,
  checked = false,
  onChange = () => {},
  error = false,
  errorMsg,
  'data-testid': testid = 'radioButton',
  'data-type': dataType = 'string',
  ...props
}) => {
  const classes = classNames({
    field: true,
    'field--radio': true,
    'field--has-error': !!error,
    'field--radio--checked': checked,
    'field--disabled': disabled,
  });

  return (
    <div className={classes}>
      <input
        {...props}
        type="radio"
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        disabled={disabled}
        checked={checked}
        data-testid={testid}
        data-type={dataType}
      />
      <label htmlFor={id} data-testid="radio">
        {label}
      </label>
      {error && <InputError errorMsg={errorMsg} />}
    </div>
  );
};

export default RadioButton;
