import classnames from 'classnames';

import Spinner from '../../shared/spinner';

interface Props {
  text: string;
  loading: boolean;
  disabled: boolean;
  hasError: boolean;
  handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const LoadingButton: React.FC<Props> = ({
  text,
  loading = false,
  disabled = false,
  hasError = false,
  handleClick,
}) => {
  const classes = classnames({
    button: true,
    'button--block': true,
    'button--tertiary': true,
    'button--loading': loading,
    'button--error': hasError,
  });

  return (
    <button
      className={classes}
      disabled={disabled}
      onClick={handleClick}
      data-testid="loadingButton"
    >
      {loading && <Spinner className="button__spinner" />}
      <span>{text}</span>
    </button>
  );
};

export default LoadingButton;
