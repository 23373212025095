import { FC, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { startCase } from 'lodash/string';
import Tooltip from 'react-tooltip-lite';
import moment from 'moment';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { omit } from 'lodash';

import { i18n as I18n } from 'utils/i18n';
import { dateFormatForUI } from 'utils/date';
import { getMinDate, getMaxDate } from 'utils/booking';

import useUpdateEffect from '../../hooks/useUpdateEffect';
import useIsMobile from '../../hooks/useIsMobile';

// components
import Field from '../fields/field';
import Checkbox from '../fields/checkbox';
import SelectList from '../fields/selectList';
import RadioButton from '../fields/radioButton';
import DatepickerField from '../fields/datepickerField';
import RecurrenceStatus from './recurrenceStatus';
import LoadingButton from './loadingButton';

import {
  SHARED_TOOLTIP_DISTANCE,
  DESKTOP_TOOLTIP_DISTANCE,
} from '../../constants/breakpoints';

import { getInitialValues } from '../../hooks/useStoredBooking';

const RecurrenceType = ['daily', 'weekly', 'monthly'];

export const WeekDay = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const WeekNumber = {
  First: 1,
  Second: 2,
  Third: 3,
  Fourth: 4,
  Last: -1,
};

interface Props {
  fieldKey?: string;
  date?: Date;
  services?: object[];
  clashCount?: number;
  updatesEnabled?: boolean;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  onCheck?: () => any;
}

const dateDependentFields = ['nthDayOfMonth', 'weekDay', 'weekDays', 'endDate'];

const emptyList = [];

const RecurrenceOptions: FC<Props> = ({
  fieldKey = 'recurrence',
  date: today,
  services = emptyList,
  clashCount = 0,
  updatesEnabled,
  loading = false,
  disabled: disabledProp,
  className: classNameProp = 'recurrence__default',
  onCheck = () => {},
}) => {
  const className = classNames('recurrence', classNameProp);

  const NUM_DAYS = 31;

  const fieldNamePrefix = `${fieldKey}.`;

  const isMobileView = useIsMobile();

  const {
    values: { date, service, slots = [], [fieldKey]: recurrence = {} } = {},
    errors: { recurrence: errors = {} } = {},
    setFieldValue,
    validateForm,
  } = useFormikContext();

  const checkedJson = useMemo(
    () => JSON.stringify(omit(recurrence, ['checked'])),
    [recurrence]
  );

  useUpdateEffect(
    () => {
      setFieldValue(`${fieldNamePrefix}checked`, false);
    },
    [setFieldValue, fieldNamePrefix, checkedJson],
    updatesEnabled
  );

  const { type: recurrenceType } = recurrence;

  useUpdateEffect(
    () => {
      const { recurrence: fields = {} } = getInitialValues({
        recurrenceRules: { type: recurrenceType },
      });

      const excludedFields = [
        'type',
        'enabled',
        'checked',
        ...dateDependentFields,
      ];

      const fieldNames = Object.keys(fields).filter(
        field => !excludedFields.includes(field)
      );

      fieldNames.forEach(fieldName => {
        setFieldValue(`${fieldNamePrefix}${fieldName}`, fields[fieldName]);
      });
    },
    [setFieldValue, fieldNamePrefix, recurrenceType],
    updatesEnabled
  );

  useUpdateEffect(
    () => {
      const { recurrence: fields = {} } = getInitialValues({
        startTime: date,
        recurrenceRules: { type: recurrenceType },
      });

      const fieldNames = dateDependentFields.filter(
        field => field !== 'endDate'
      );

      const setFields = async () => {
        await Promise.all(
          fieldNames.map(fieldName =>
            setFieldValue(`${fieldNamePrefix}${fieldName}`, fields[fieldName])
          )
        );
        await validateForm();
      };

      setFields();
    },
    [setFieldValue, validateForm, fieldNamePrefix, date, recurrenceType],
    updatesEnabled
  );

  const maxDate = useMemo(
    () =>
      getMaxDate(
        today || moment().toDate(),
        services.find(({ id }) => String(id) === service)
      ),
    [services, service, today]
  );

  const minDate = useMemo(() => getMinDate(date, recurrence), [
    date,
    recurrence,
  ]);

  const minDateString = useMemo(() => (minDate ? String(minDate) : undefined), [
    minDate,
  ]);

  useUpdateEffect(
    () => {
      const setFields = async () => {
        await setFieldValue(
          `${fieldNamePrefix}endDate`,
          new Date(minDateString)
        );
        await validateForm();
      };

      if (minDateString) setFields();
    },
    [setFieldValue, validateForm, fieldNamePrefix, minDateString],
    updatesEnabled
  );

  const {
    enabled: recurrenceEnabled,
    checked: recurrenceChecked,
    everyNumberOf,
    weekDays = [],
    dayRange,
    dayOfMonthRange,
    rangeEndType,
  } = recurrence;

  useUpdateEffect(
    () => {
      if (!recurrenceEnabled) {
        const { recurrence: fields } = getInitialValues({ startTime: date });
        Object.keys(fields).forEach(fieldName => {
          setFieldValue(`${fieldNamePrefix}${fieldName}`, fields[fieldName]);
        });
      }
    },
    [setFieldValue, fieldNamePrefix, recurrenceEnabled, date],
    updatesEnabled
  );

  const disabled =
    disabledProp !== undefined
      ? disabledProp
      : !date || !service || slots.length === 0;

  const numericOptions = count => {
    return Array(count)
      .fill(null)
      .map((_, day) => ({
        label: (day + 1).toString(),
        value: day + 1,
      }));
  };

  const recurrenceTypeOptions = () => {
    return RecurrenceType.map(key => {
      return {
        label: startCase(key),
        value: key,
      };
    });
  };

  const weeklyOptions = () => {
    return WeekDay.map(key => {
      return {
        label: startCase(key).slice(0, 3),
        value: key,
      };
    });
  };

  const monthlyOptions = () => {
    return Object.keys(WeekNumber).map(key => {
      return {
        label: key,
        value: WeekNumber[key],
      };
    });
  };

  const maxOccurrences = {
    daily: Math.ceil(
      moment(maxDate).diff(moment(minDate), 'days', true) *
        (dayRange === 'weekdays' ? 5 / 7 : 1)
    ),
    weekly: Math.ceil(moment(maxDate).diff(moment(minDate), 'weeks', true)),
    monthly: Math.ceil(moment(maxDate).diff(moment(minDate), 'months', true)),
  };

  const clashesDetected = recurrenceChecked && clashCount > 0;

  const buttonText = clashesDetected
    ? I18n.t('quick_book.recurrence.fix_clashes')
    : I18n.t('quick_book.recurrence.check_and_reserve');

  const renderDailyOptions = () => (
    <>
      <div className="recurrence__option recurrence__option--frequency-range">
        <Field
          name="dayRange"
          namePrefix={fieldNamePrefix}
          as={RadioButton}
          type="radio"
          id="recurrenceDays"
          value="days"
          label={I18n.t('quick_book.recurrence.every')}
        />
        <Field
          name="everyNumberOf"
          namePrefix={fieldNamePrefix}
          as={SelectList}
          setValueProp="onChange"
          label={I18n.t('quick_book.recurrence.recur_every')}
          hideLabel
          options={numericOptions(NUM_DAYS)}
          disabled={dayRange !== 'days'}
          data-testid="everyNumberOf"
        />
        <span>{I18n.t('quick_book.recurrence.days')}</span>
      </div>
      <div className="recurrence__option recurrence__option--every-weekday">
        <Field
          name="dayRange"
          namePrefix={fieldNamePrefix}
          as={RadioButton}
          type="radio"
          id="recurrenceWeekdays"
          value="weekdays"
          label={I18n.t('quick_book.recurrence.every_weekday')}
        />
      </div>
    </>
  );

  const renderWeeklyOptions = () => (
    <>
      <div className="recurrence__option recurrence__option--recur-every">
        <Field
          name="everyNumberOf"
          namePrefix={fieldNamePrefix}
          as={SelectList}
          setValueProp="onChange"
          label={I18n.t('quick_book.recurrence.recur_every')}
          hideLabel={false}
          options={numericOptions(20)}
        />
        <span>weeks on</span>
      </div>
      <div className="recurrence__option recurrence__option--selected-weekday">
        {WeekDay.map(day => {
          return (
            <div className="recurrence__option__day" key={day}>
              <Field
                name="weekDays"
                namePrefix={fieldNamePrefix}
                as={Checkbox}
                type="checkbox"
                id={day}
                label={day.slice(0, 3)}
                value={day}
              />
            </div>
          );
        })}
      </div>
    </>
  );

  const renderMonthlyOptions = () => (
    <>
      <div className="recurrence__option recurrence__option--frequency-range">
        <Field
          name="dayOfMonthRange"
          namePrefix={fieldNamePrefix}
          as={RadioButton}
          type="radio"
          id="recurrence_day_of_month"
          value="nthDayOfMonth"
          label={I18n.t('quick_book.recurrence.day')}
          data-testid="nthDayOfMonth"
        />
        <Field
          name="nthDayOfMonth"
          namePrefix={fieldNamePrefix}
          as={SelectList}
          setValueProp="onChange"
          hideLabel
          disabled={dayOfMonthRange !== 'nthDayOfMonth'}
          options={numericOptions(31)}
          testid="dayNumber"
        />
        <span>
          each month
          <Tooltip
            content={I18n.t('quick_book.recurrence.monthly_days_help_text')}
            className="recurrence__tooltip"
            distance={
              isMobileView ? SHARED_TOOLTIP_DISTANCE : DESKTOP_TOOLTIP_DISTANCE
            }
          >
            <ReactSVG
              src="/images/icons/question-mark.svg"
              width={17}
              height={17}
            />
          </Tooltip>
        </span>
      </div>
      <div className="recurrence__option recurrence__option--frequency-range">
        <Field
          name="dayOfMonthRange"
          namePrefix={fieldNamePrefix}
          as={RadioButton}
          type="radio"
          id="recurrence_day_of_week_of_month"
          value="nthWeekdayOfMonth"
          label={I18n.t('quick_book.recurrence.every')}
          data-testid="nthWeekdayOfMonth"
        />
        <Field
          name="weekNumber"
          namePrefix={fieldNamePrefix}
          as={SelectList}
          setValueProp="onChange"
          hideLabel
          options={monthlyOptions()}
          disabled={dayOfMonthRange !== 'nthWeekdayOfMonth'}
          testid="weekNumber"
        />
        <Field
          name="weekDay"
          namePrefix={fieldNamePrefix}
          as={SelectList}
          setValueProp="onChange"
          hideLabel
          options={weeklyOptions()}
          disabled={dayOfMonthRange !== 'nthWeekdayOfMonth'}
          testid="weekDay"
        />
      </div>
    </>
  );

  const renderOptions = () => {
    switch (recurrenceType) {
      case 'daily': {
        return renderDailyOptions();
      }

      case 'weekly': {
        return renderWeeklyOptions();
      }

      case 'monthly': {
        return renderMonthlyOptions();
      }

      default: {
        return null;
      }
    }
  };

  const renderRangeOfRecurrence = () => (
    <>
      <h4 className="recurrence__text">
        {I18n.t('quick_book.recurrence.text')}
      </h4>

      <div className="recurrence__option recurrence__option--end-by">
        <Field
          name="rangeEndType"
          namePrefix={fieldNamePrefix}
          as={RadioButton}
          type="radio"
          id="recurrenceRangeEndDate"
          value="date"
          label={I18n.t('quick_book.recurrence.end_by')}
        />
        <Field
          name="endDate"
          namePrefix={fieldNamePrefix}
          as={DatepickerField}
          setValueProp="onChange"
          dateFormat={dateFormatForUI}
          minDate={minDate}
          maxDate={new Date(maxDate)}
          disabled={rangeEndType !== 'date'}
        />
      </div>
      <div className="recurrence__option recurrence__option--end-after">
        <Field
          name="rangeEndType"
          namePrefix={fieldNamePrefix}
          as={RadioButton}
          type="radio"
          id="recurrenceRangeEndCount"
          value="count"
          label={I18n.t('quick_book.recurrence.end_after')}
        />
        <div className="field__input">
          <Field
            name="count"
            namePrefix={fieldNamePrefix}
            type="number"
            min="2"
            max={maxOccurrences[String(recurrenceType)]}
            step="1"
            disabled={rangeEndType !== 'count'}
            data-testid="recurrenceCount"
          />
        </div>
        <span>occurrences</span>

        {(errors.count || errors.endDate) && (
          <div
            className="recurrence__max_occurences_error"
            data-testid="maxOccurrencesError"
            data-cy="maxOccurrencesError"
          >
            <i className="fas fa-exclamation-triangle space__icon" />
            {errors.count || errors.endDate}
          </div>
        )}
      </div>
    </>
  );

  const requiredOptionsSelected = () => {
    if (errors.count || errors.endDate) {
      return false;
    }

    switch (recurrenceType) {
      case 'daily': {
        return !!everyNumberOf || dayRange === 'weekdays';
      }

      case 'weekly': {
        return weekDays.length;
      }

      case 'monthly': {
        return true;
      }

      default: {
        return false;
      }
    }
  };

  const renderEnabledCheckbox = () => (
    <Field
      name="enabled"
      namePrefix={fieldNamePrefix}
      as={Checkbox}
      type="checkbox"
      label={I18n.t('quick_book.recurrence.set_booking_as_recurring')}
      disabled={disabled}
      data-testid="toggleRecurrence"
    />
  );

  if (!recurrenceEnabled || disabledProp)
    return <div className={className}>{renderEnabledCheckbox()}</div>;

  const handleClick = () => {
    if (!clashesDetected) setFieldValue(`${fieldNamePrefix}checked`, true);

    onCheck(clashesDetected);
  };

  return (
    <div className={className} id="recurrence__quickbook">
      {renderEnabledCheckbox()}
      <div className="recurrence__container" data-testid="recurrenceContainer">
        <div className="recurrence__row">
          <div>
            <div className="recurrence__option recurrence__option--frequency">
              <Field
                name="type"
                namePrefix={fieldNamePrefix}
                as={SelectList}
                setValueProp="onChange"
                label="Recurrence"
                hideLabel={false}
                options={recurrenceTypeOptions()}
              />
            </div>
            {renderOptions()}
          </div>
          <div>{renderRangeOfRecurrence()}</div>
        </div>

        <div>
          {!errors.count && !errors.endDate && (
            <RecurrenceStatus
              statusChecked={recurrenceChecked}
              clashCount={clashCount}
            />
          )}

          <LoadingButton
            text={buttonText}
            loading={loading}
            disabled={loading || !requiredOptionsSelected()}
            hasError={clashesDetected}
            handleClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
};

export default RecurrenceOptions;
